<script setup lang="ts">
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { Variants } from "../types/tableOfContents";

interface TocLink {
  id: string;
}

const router = useRouter();

const props = defineProps<{
  activeTocId: string;
  tocLinks: TocLink[];
  page: string;
  variant?: Variants;
}>();

const { t } = useI18n();

const tocContainerRef = ref<HTMLElement | null>(null);

const onClick = (id: string) => {
  const el = document.getElementById(id);
  if (el) {
    router.push({ hash: `#${id}` });
    el.scrollIntoView({ behavior: "smooth" });
  }
};
</script>

<template>
  <div
    ref="tocContainerRef"
    class="toc-container"
    :class="{ [`toc-container--variant-${variant}`]: variant }"
  >
    <div class="rounded-lg toc-container--text-box">
      <nav>
        <ul class="ml-0">
          <li
            v-for="link in props.tocLinks"
            :key="link.id"
            class="toc-link cursor-pointer text-sm ml-0 mb-2 py-4 position-relative text-uppercase"
            @click="onClick(link.id)"
          >
            <span v-html="t(`tocSlugs.${page}.${link.id}`)"></span>
            <span v-if="link.id === props.activeTocId" class="highlight"></span>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<style lang="scss">

.toc-container {
  width: auto;
  max-width: 350px;

  &--variant-saxony-ai {
    .highlight {
      background-image: linear-gradient(
        to bottom,
        #f26d85,
        #fa8f3c,
        #82d9d0,
        #37abd9
      );
    }

    .toc-container--text-box {
      backdrop-filter: blur(40px) !important;
      background: rgba(255, 255, 255, 0.5);
      padding: 10px 20px;
      @media only screen and (min-width: 1960px) {
        padding: 32px 64px;
      }
    }
  }

  .highlight {
    display: inline-block;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 56px;
    background-color: rgb(var(--v-theme-primary));
  }

  ul {
    padding-left: 0;
  }

  .toc-link {
    list-style: none;
    min-width: 164px;
    padding-right: 32px;
    padding-left: 8px;
    word-break: break-word;
  }

}
</style>
